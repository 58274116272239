import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"


/**
 * In this functional component a fullscreen <BackgroundImage /> may be created
 * with art-directed images.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */



const HeaderImage = ({className, props }) => {

  const backgroundArtDirectionStack = [
    props.small,
    {
      ...props.medium,
      media: `(min-width: 491px)`,
    },
    {
      ...props.large,
      media: `(min-width: 1401px)`,
    },
  ]

  return (

      <StyledBackgroundImage
        Tag="section"
        className={className}
        title={props.label}
        fluid={backgroundArtDirectionStack}
        backgroundColor={`#040e18`}
        id="adfullscreenbg"
        role="img"
        aria-label={props.AriaLabel}
        preserveStackingContext={true}
        textDirection={props.textDirection}
      >
        <TextWrapper>
          <StyledHeader>{props.title}</StyledHeader>
          {props.subtext && <StyledSubText>{props.subtext} </StyledSubText>}
        </TextWrapper>
      </StyledBackgroundImage>

  )
}

const StyledBackgroundImage = styled(BackgroundImage)`
  display: flex;
  justify-content: ${props => props.textDirection || "flex-start"};;
  align-items:center;
`


const StyledHeaderImage = styled(HeaderImage)`
  width: 100%;
  height:300px;
  display: flex;

  background-position: bottom left;

  @media (min-width: 992px) {
      height:400px;
  }

`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:  flex-start;
  justify-content: center;

  margin: 0 2rem;

  @media (min-width: 992px) {
    margin:0 ;
    padding-left: 5vw;
    width: 50%;
  }
`

const StyledSubText = styled.p`
  color: white;
  text-align: left;
  margin-top: 0.2rem;
  font-size: 1.25rem;
  width: 50%;

  margin-bottom: 2.5rem;
  width: 100%;

  @media (min-width: 992px) {
    font-size: 1.75rem;
  }
`


const StyledHeader = styled.h1`
  flex-direction: column;
  color: white;
  text-align: left;
  font-size: 2rem;

  @media (min-width: 768px) { 
    font-size: 2.5rem;
   }
  @media (min-width: 992px) {
    font-size: 3rem;
  }
`

export default StyledHeaderImage

import React from "react"
import styled from "styled-components"
import Accordion from "./AccordionMobile"


const FAQWrapper = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;

`

const FAQ = (props) => {

    return (
        <FAQWrapper>
        {props.data.map((item, index) => (
          <Accordion
            props={item}
            key={item.title + index}
          />

        ))}
        </FAQWrapper>
    )



}

export default FAQ
import React, { useState } from "react"
import styled from "styled-components"
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown"

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  border: none;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 2px;
`

const StyledAccordionWrapper = styled.div`
  background-colour: blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  margin-bottom: 1rem;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.28);
  border-radius: 2px;
  border: 1px solid;
  border-color: var(--light-grey);
`

const StyledContentWrapper = styled.div`
  width: 100%;
  max-height: ${props => (props.open ? "1000px" : "0")};
  transition: all 1s linear;
  overflow: hidden;
  text-align: left;
`
const StyledText = styled.div`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color:#3E3E3E;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`

const StyledTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  padding: 0.75rem 0;
  padding-left: 1rem;
  margin: 0;
  color:#3E3E3E;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`

const StyledIcon = styled.div`
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
  color: var(--secondary-medium);
  transition: all 0.3s linear;
  transition-delay: ${props => (props.open ? "inherit" : "0.6s")};
  transform: ${props => (props.open ? "rotate(180deg)" : "inherit")};
`

const Accordion = ({props}) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <StyledAccordionWrapper>
      <StyledButton onClick={handleClick}>
        <StyledTitle>{props.title}</StyledTitle>
        <StyledIcon open={open}>
          <FiChevronDown />
        </StyledIcon>
      </StyledButton>

      <StyledContentWrapper open={open}>
        <StyledText dangerouslySetInnerHTML={{ __html: props.body }}/>
      </StyledContentWrapper>
    </StyledAccordionWrapper>
  )
}

Accordion.defaultProps = {
  title: "title",
  body: "Body",
}

export default Accordion

import React from "react"

import Seo from "../../components/seo"
import styled from "styled-components"

import Faq from "../../components/Accordion/FaqMobile"
import HeaderImage from "../../components/Headers/HeaderImage3"
import { graphql } from "gatsby"

const MainWrapper = styled.div`
  padding: 1rem 1rem;
  background-color:#FFFCF9;
`

const SectionHeader = styled.h1`
  margin: 1rem 0;
  
  font-weight: bold;
  font-size: 2rem;

  @media (min-width: 801px) {
    margin: 1rem 10vw;
    font-size: 3rem;
  }

  :first-child {
    margin-top: 2rem;
  }
`

export default function Page({ data }) {
  const Header = {
    label: "HeaderImage",
    AriaLabel: "HeaderImage",
    title: "Häufig gestellte Fragen",
    small: data.HeaderImage_small.childImageSharp.fluid,
    medium: data.HeaderImage_medium.childImageSharp.fluid,
    large: data.HeaderImage_large.childImageSharp.fluid,
    alt: "Bild zu häufig gestellte Fragen",
  }

  return (
    <>
      <Seo />
      <HeaderImage props={Header} />
      <MainWrapper>
        <SectionHeader>Allgemein</SectionHeader>
        <Faq data={Section1} />

        <SectionHeader>Service</SectionHeader>
        <Faq data={Section2} />
        <SectionHeader>Benachrichtigungen</SectionHeader>
        <Faq data={Section3} />
      </MainWrapper>
    </>
  )
}

const Section1 = [
  {
    title: "Ich erhalte meine Benachrichtigungen nicht.",
    body: "Einige Mobiltelefonhersteller, wie zum Beispiel Huawei oder OnePlus, verwenden eine sehr aggressive Batterieoptimierung, welche verhindern können, dass Nachrichten angezeigt werden. <br/>Hilfestellungen zu verschiedenen Anbietern finden Sie weiter unten auf dieser Seite.",
  },
  {
    title: "Wo kann ich meine Erinnerungen anpassen?",
    body: "Sie können Ihre Erinnerungen in den Einstellungen unter 'Erinnerungen anpassen' ändern oder deaktivieren.",
  },
  {
    title: "Wie kann ich ein neues Rezept anfordern?",
    body: "Sie finden diese Option unter 'Rezepte'. Dort können Sie wie folgt ein neues Rezept bei Ihrem Arzt anfordern: <br/> 1. Klicken Sie oben rechts auf 'Rezept neu anfordern'.  <br/> 2. Wählen Sie die gewünschten Rezepte aus, indem Sie auf den gelben Kreis klicken. <br/> 3. Klicken Sie oben rechts auf 'Rezept anfordern'.  <br/> 4. Kontrollieren und versenden Sie das vorgeschriebene E-Mail.",
  },
  {
    title: "Wie kann ich ein neues Rezept einreichen?",
    body: "Sie finden diese Option unter 'Rezepte'. Klicken Sie auf 'Neues Rezept einreichen' und folgen Sie den Anweisungen auf dem Bildschirm.",
  },
  {
    title: "Wie kann ich ein Medikament ändern?",
    body: "Sie finden diese Option unter 'Medikamente'. Folgen Sie den nachfolgenden Schritten um der Apotheke eine Änderung mitzuteilen: <br/> 1. Klicken Sie rechts oben auf 'Medikament ändern'. <br/> 2. Wählen Sie die gewünschten Medikamente aus, indem Sie auf den gelben Kreis klicken. <br/> 3. Klicken Sie rechts oben auf das Stift-Symbol. <br/>4. Folgen Sie den Anweisungen auf dem Bildschirm.",
  },
  {
    title: "Was muss ich tun, wenn ich ein Medikament nicht mehr einnehmen möchte?",
    body: "Sie können der Apotheke über die App mitteilen, dass Sie ein Medikament nicht mehr einnehmen möchten. <br/>Dazu folgen Sie bitte folgenden Schritten: <br/> 1. Navigieren Sie auf die Medikamentenseite<br/> 2. Klicken Sie rechts oben auf 'Medikament ändern'.<br/> 3. Wählen Sie die gewünschten Medikamente aus, indem Sie auf den gelben Kreis klicken <br/> 4. Klicken Sie rechts oben auf den roten Mülleimer. <br/> 5. Folgen Sie den Anweisungen auf dem Bildschirm.",
  },
]

const Section2 = [
  {
    title: "Wo kann ich mein Abonnement kündigen?",
    body: "Sie können Ihr Abonnement in den Einstellungen unter 'Accounteinstellungen' kündigen.",
  },
  {
    title: "Wo kann ich meine Zahlungsdaten / Kreditkarteninformationen ändern?",
    body: "Sie können Ihre Zahlungsdaten in den Einstellungen unter 'Accounteinstellungen' ändern.",
  },
]
const Section3 = [
  {
    title: "Mein Huawei zeigt keine Benachrichtigungen an.",
    body: "Huawei ist bekannt dafür, dass Sie Apps im Hintergrund unterdrücken. Um dies zu beheben, können Sie wie folgt vorgehen: <br/> <br/>1. Öffnen Sie die Einstellungen <br/>2. Wählen Sie Benachrichtigungen aus<br/>3. Wählen Sie DoryGo aus<br/>4. Klicken Sie auf Benachrichtigungen zulassen. <br/><br/>Zusätzlich können Sie folgende Einstellung anpassen: <br/>1. Einstellungen <br/>2. Akku auswählen<br/>3. App-Start auswählen <br/>4. DoryGo deaktivieren (auf manuell setzen). <br/><br/>Leider unterscheiden sich die genauen Einstellungen je nach Handyversion. Bei Fragen helfen wir Ihnen gerne persönlich weiter. Sie erreichen uns unter hello@doryhealth.com oder telefonisch unter +41 44 551 43 74.",
  },
  {
    title: "Mein Hersteller ist nicht aufgelistet.",
    body: "Wir helfen Ihnen gerne persönlich weiter. Sie erreichen uns unter hello@doryhealth.com oder telefonisch unter +41 44 551 43 74.",
  },

]


export const query = graphql`
  query {
    HeaderImage_large: file(relativePath: { eq: "seamless-bg-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HeaderImage_medium: file(relativePath: { eq: "seamless-bg-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HeaderImage_small: file(relativePath: { eq: "seamless-bg-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 490) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
